import ExperienceForm from "../ExperienceForm";
import { useState } from "react";
import GrigriResult from "../GrigriResult";
import Loader from "../Loader";
import config from "../../config";

function Experience() {
    const [pictureData, setPictureData] = useState();
    const [formData, setFormData] = useState();
    const handleSubmit = (formData, rgpd) => {
        if (!rgpd) {
            formData = { ...formData, name: null };
        }
        setFormData(formData);
    };

    const onCanvasGenerated = (dataUrl, grigriNum) => {

        setTimeout(() => {
            setPictureData({dataUrl, grigriNum})
        }, window.innerWidth < config.canvas.maxWidth ? 500 : 2000 ) //  
    }

    return (
        <div className="px-6 md:px-14">
            {!formData && <ExperienceForm handleSubmit={handleSubmit} />}
            {formData && !pictureData && (
                <Loader formData={formData}
                    onCanvasGenerated={onCanvasGenerated}
                />
            )}
            {formData && pictureData && <GrigriResult formData={formData} pictureData={pictureData} />}
        </div>
    );
}

export default Experience;
