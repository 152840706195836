import ButtonLink from "../ButtonLink";
import config from "../../config";
import { ReactComponent as Bg } from "../../assets/bg.svg";
import { useContext } from "react";
import { ThemeContext } from "../../context/theme";
import SmallText from "../SmallText";

function Home() {
    const { palette } = useContext(ThemeContext);
    const textStyle = { color: palette[1] };

    return (
        <div style={textStyle} className="flex flex-col md:flex-row">
            <div className="px-6 md:px-12 w-full md:w-1/2">
                <h1 className="text-[40px]/tight md:text-7xl/tight mb-4 font-TTNormsProMed">
                    Votre Grigri Digital<br /> pour 2024
                </h1>
                <p className="mb-8 text-2xl md:text-2xl font-TTNormsProMed">
                    Cette année, osez croire en l'extraordinaire.
                </p>
                <SmallText margin="mb-6">
                    Oubliez les résolutions classiques et ennuyeuses. Cette
                    année on vous offre un petit bijou technologique,
                    mi-porte-bonheur, mi-totem magique, qui va pimenter votre
                    quotidien et propulser vos business goals dans la
                    stratosphère.
                </SmallText>
                <SmallText margin="mb-4 md:mb-6">
                    Imaginez un peu : une productivité qui frôle l'overdose de
                    caféine, un 'brand love' tellement intense que vos clients
                    vous enverraient des cartes de Saint-Valentin et des KPIs
                    qui montent si haut qu'ils pourraient capter une forme de
                    vie sur Mars.
                </SmallText>
                <SmallText margin="mb-8 md:mb-10">
                    Chaque Grigri est unique – conçu spécialement pour vous avec
                    un zeste d’amour et une pincée de poudre de perlimpinpin
                    numérique.
                </SmallText>
                <ButtonLink
                    color={palette[0]}
                    bgColor={palette[1]}
                    bgHover={palette[2]}
                    to={`${config.baseLink}experience`}
                >
                    Génerer mon Grigri
                </ButtonLink>
            </div>
            <div className="mt-16 w-full md:mt-0 md:w-1/2 md:text-right">
                <Bg className="w-100 md:inline md:w-auto" />
            </div>
        </div>
    );
}

export default Home;
