import { NavLink } from "react-router-dom";
import config from "../config";
import { ReactComponent as Logo } from "../assets/intuiti.svg";
import { useContext } from "react";
import { ThemeContext } from "../context/theme";

function Header() {
    const { palette } = useContext(ThemeContext);
    const linkStyle = {color: palette[1], borderBottom: `1px solid ${palette[1]}`, paddingBottom: "0.2rem"};

    return (
        <header className={`p-6 md:p-12 pb-12 md:pb-16 flex justify-between items-center`}>
            <NavLink to={`${config.baseLink}`}><Logo fill={palette[1]} /></NavLink>
            <nav>
                <NavLink className="mr-3 md:mr-6 text-lg md:text-xl" style={linkStyle} to={`${config.baseLink}a-propos`}>Infos</NavLink>
                <NavLink className="text-lg md:text-xl" style={linkStyle} to={`${config.baseLink}digitalgrigris`}>
                    Galerie
                </NavLink>
            </nav>
        </header>
    );
}

export default Header;
