import React, { useState } from "react";

export const ThemeContext = React.createContext();

// [primary, secondary]
export const paletteRange = [
    ["#F59A65", "#0051CD"],
    ["#0051CD", "#F59A65"],
    ["#FFF2FA", "#F59A65"],
    ["#F59A65", "#FFF2FA"],
    ["#85BFFF", "#2F05D9"],
    ["#2F05D9", "#85BFFF"],
    ["#C3FDB3", "#516349"],
    ["#516349", "#C3FDB3"],
    ["#7000FF", "#FFE9BF"],
    ["#FFE9BF", "#7000FF"],
    ["#AC9AF6", "#FFE9BF"],
    ["#FFE9BF", "#AC9AF6"],
    ["#95395A", "#C1DEFF"],
    ["#C1DEFF", "#95395A"],
    ["#DD4A65", "#FFFA7E"],
    ["#FFFA7E", "#DD4A65"],
    ["#DD4A65", "#FFBCE4"],
    ["#FFBCE4", "#DD4A65"],
];

export function ThemeProvider({ children }) {
    // palette
    const paletteIndex = Math.floor(Math.random() * paletteRange.length);

    const initPalette = paletteRange[paletteIndex];
    const [palette, setPalette] = useState(initPalette);

    return (
        <ThemeContext.Provider value={{ palette, setPalette }}>
            {children}
        </ThemeContext.Provider>
    );
}
