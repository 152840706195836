export const svgLogo = [
    { type: 'beginShape' },
    { type: 'vertex', params: [160.5, 46] },
    { type: 'vertex', params: [169.7, 46] },
    { type: 'vertex', params: [169.7, 9.2] },
    { type: 'vertex', params: [178.9, 9.2] },
    { type: 'vertex', params: [178.9, 0] },
    { type: 'vertex', params: [151.4, 0] },
    { type: 'vertex', params: [151.4, 9.2] },
    { type: 'vertex', params: [160.6, 9.2] },
    { type: 'vertex', params: [160.6, 46] },
    { type: 'endShape' },
    { type: 'beginShape' },
    { type: 'vertex', params: [192.6, 0] },
    { type: 'vertex', params: [183.4, 0] },
    { type: 'vertex', params: [183.4, 9.2] },
    { type: 'vertex', params: [192.6, 9.2] },
    { type: 'vertex', params: [192.6, 0] },
    { type: 'endShape' },
    { type: 'beginShape' },
    { type: 'vertex', params: [9.2, 0] },
    { type: 'vertex', params: [0, 0] },
    { type: 'vertex', params: [0, 9.2] },
    { type: 'vertex', params: [9.2, 9.2] },
    { type: 'vertex', params: [9.2, 0] },
    { type: 'endShape' },
    { type: 'beginShape' },
    { type: 'vertex', params: [58.7, 9.2] },
    { type: 'vertex', params: [67.9, 9.2] },
    { type: 'vertex', params: [67.9, 46] },
    { type: 'vertex', params: [77, 46] },
    { type: 'vertex', params: [77, 9.2] },
    { type: 'vertex', params: [86.2, 9.2] },
    { type: 'vertex', params: [86.2, 0] },
    { type: 'vertex', params: [58.7, 0] },
    { type: 'vertex', params: [58.7, 9.2] },
    { type: 'endShape' },
    { type: 'beginShape' },
    { type: 'vertex', params: [146.7, 0] },
    { type: 'vertex', params: [137.5, 0] },
    { type: 'vertex', params: [137.5, 9.2] },
    { type: 'vertex', params: [146.7, 9.2] },
    { type: 'vertex', params: [146.7, 0] },
    { type: 'endShape' },
    { type: 'beginShape' },
    { type: 'vertex', params: [35.2, 0] },
    { type: 'vertex', params: [34.4, 0] },
    { type: 'bezierVertex', params: [34.4, 0, 28.4, 0, 22.9, 3.2] },
    { type: 'vertex', params: [22.9, 0] },
    { type: 'vertex', params: [13.7, 0] },
    { type: 'vertex', params: [13.7, 27.6] },
    { type: 'vertex', params: [22.9, 27.6] },
    { type: 'vertex', params: [22.9, 20.9] },
    { type: 'bezierVertex', params: [22.9, 9.2, 34.5, 9.2, 34.5, 9.2] },
    { type: 'vertex', params: [35, 9.2] },
    { type: 'bezierVertex', params: [46.6, 9.2, 46.6, 20.9, 46.6, 20.9] },
    { type: 'vertex', params: [46.6, 46] },
    { type: 'vertex', params: [55.8, 46] },
    { type: 'vertex', params: [55.8, 20.8] },
    { type: 'bezierVertex', params: [55.9, 20.8, 55.9, 0, 35.2, 0] },
    { type: 'endShape' },
    { type: 'beginShape' },
    { type: 'vertex', params: [9.2, 18.4] },
    { type: 'vertex', params: [0, 18.4] },
    { type: 'vertex', params: [0, 46] },
    { type: 'vertex', params: [9.2, 46] },
    { type: 'vertex', params: [9.2, 18.4] },
    { type: 'endShape' },
    { type: 'beginShape' },
    { type: 'vertex', params: [123.8, 25.1] },
    { type: 'bezierVertex', params: [123.8, 36.8, 112.2, 36.8, 112.2, 36.8] },
    { type: 'vertex', params: [111.7, 36.8] },
    { type: 'bezierVertex', params: [100, 36.8, 100, 25.1, 100, 25.1] },
    { type: 'vertex', params: [100, 0] },
    { type: 'vertex', params: [90.8, 0] },
    { type: 'vertex', params: [90.8, 25.2] },
    { type: 'bezierVertex', params: [90.8, 25.2, 90.8, 46, 111.5, 46] },
    { type: 'vertex', params: [112.3, 46] },
    { type: 'bezierVertex', params: [112.3, 46, 118.3, 46, 123.8, 42.8] },
    { type: 'vertex', params: [123.8, 46] },
    { type: 'vertex', params: [132.9, 46] },
    { type: 'vertex', params: [132.9, 18.4] },
    { type: 'vertex', params: [123.7, 18.4] },
    { type: 'vertex', params: [123.7, 25.1] },
    { type: 'endShape' },
    { type: 'beginShape' },
    { type: 'vertex', params: [146.7, 18.4] },
    { type: 'vertex', params: [137.5, 18.4] },
    { type: 'vertex', params: [137.5, 46] },
    { type: 'vertex', params: [146.7, 46] },
    { type: 'vertex', params: [146.7, 18.4] },
    { type: 'endShape' },
    { type: 'beginShape' },
    { type: 'vertex', params: [192.6, 18.4] },
    { type: 'vertex', params: [183.4, 18.4] },
    { type: 'vertex', params: [183.4, 46] },
    { type: 'vertex', params: [192.6, 46] },
    { type: 'vertex', params: [192.6, 18.4] },
    { type: 'endShape' }
  ]
