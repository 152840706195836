import { useContext } from "react";
import ButtonLink from "../ButtonLink";
import { ThemeContext } from "../../context/theme";
import config from "../../config";
import SmallText from "../SmallText";
import { ReactComponent as Apropos } from "../../assets/bgApropos.svg";

function About() {
    const { palette } = useContext(ThemeContext);
    return (
        <div className="px-6 md:px-14">
            <div className="flex flex-wrap">
                <div className="w-full md:w-1/2">
                    <h1 className="text-5xl md:text-7xl/tight mb-12 font-TTNormsProMed">
                        À propos des Grigris Digitaux
                    </h1>
                    <p className="mb-6 text-xl md:text-2xl font-TTNormsProMed">
                        Bienvenue dans le monde fascinant des Grigris Digitaux,
                        votre nouveau compagnon pour une année 2024
                        exceptionnelle !
                    </p>
                    <h2 className="text-2xl mb-4">Qu'est-ce qu'un Grigri Digital ?</h2>
                    <SmallText margin="mb-10">
                        Le Grigri digital est une création unique, mêlant
                        humour, technologie de pointe et une pincée de magie
                        numérique. Conçu comme un totem digital, chaque Grigri
                        est programmé pour apporter chance, réussite et sourires
                        dans votre vie professionnelle et personnelle.
                    </SmallText>
                    <ButtonLink
                        className="mb-10"
                        color={palette[0]}
                        bgColor={palette[1]}
                        to={`${config.baseLink}experience`}
                    >
                        Génerer mon grigri
                    </ButtonLink>
                    <h2 className="text-2xl mb-4">La Génération Technique</h2>
                    <SmallText margin="mb-10">
                        Chaque Grigri est conçu avec amour en utilisant P5.js,
                        une bibliothèque JavaScript pour la programmation
                        créative. Votre Grigri est généré en fonction de vos
                        préférences personnelles, créant une oeuvre digitale
                        vraiment personnalisé.
                    </SmallText>
                    <h2 className="text-2xl mb-4">Intuiti</h2>
                    <SmallText margin="mb-10">
                        Intuiti est une agence digitale créative, spécialisée en
                        marketing et en activations uniques. Nous fusionnons
                        créativité et technologie pour élaborer des stratégies
                        digitales personnalisées, en plaçant l'humain et
                        l'émotion au cœur de chaque projet. Notre objectif ?
                        Créer des campagnes impactantes qui résonnent
                        authentiquement avec le public, tout en valorisant une
                        expérience client inoubliable.
                    </SmallText>
                </div>
                <div className="w-full mt-8 md:w-1/2 md:pl-32 xl:text-right">
                    <Apropos className="w-full" />
                </div>
            </div>
        </div>
    );
}

export default About;
