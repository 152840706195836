import styled from "styled-components";

const StyledBtn = styled.button`
    color: ${(props) => props.$bgcolor};
    border: ${(props) => `1px solid ${props.$bgcolor}`};
    background-color: ${(props) => props.$color};
    border-radius: 100px;
    padding: 1rem 2rem;
    transition: background-color 0.3s ease;
    cursor: pointer;
    font-size: 1.1rem;
    &:hover {
        background-color: ${(props) => props.$bgcolor};
        color: ${(props) => props.$color};
        border: 1px solid transparent;
        
    }
`;

function ActionButton({ children, className, bgColor, color, disabled }) {
    return (
        <StyledBtn disabled={disabled} className={className} $bgcolor={bgColor} $color={color}>
            {children}
        </StyledBtn>
    );
}

export default ActionButton;
