import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledBtnLink = styled(Link)`
  background-color: ${(props) => props.$bgcolor};
  color: ${(props) => props.$color};
  border-radius: 100px;
  padding: 1rem 3rem;
  transition: background-color 0.3s ease;
  border: 1px solid transparent;
  display: inline-block;
  &:hover {
    color: ${(props) => props.$bgcolor};
    border: ${(props) => `1px solid ${props.$bgcolor}`};
    background-color: ${(props) => props.$color};
  }
`;

function ButtonLink({to, children, bgColor, color, className}) {
    return (
        <StyledBtnLink className={`text-lg ${className}`} to={to} $bgcolor={bgColor} $color={color}>
      {children}
    </StyledBtnLink>
     );
}

export default ButtonLink;