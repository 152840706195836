import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ButtonLink from "../ButtonLink";
import { ThemeContext } from "../../context/theme";
import config from "../../config";

function Gallery() {
  const [galleryData, setGalleryData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const { palette } = useContext(ThemeContext);

  const fetchGallery = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/gallery?page=${page}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      setHasMore(data.length > 0);

      setGalleryData((prevData) => [...prevData, ...data]);
    } catch (error) {
      console.error("Error fetching gallery:", error.message);
    }
  };

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    fetchGallery();
  }, [page]);

  return (
    <div className="px-6 md:px-14">
      <h1 className="text-5xl md:text-7xl mb-12 font-TTNormsProMed">La galerie des grigris</h1>
      <p className="mb-10 lg:w-7/12">
        Ici, explorez la galerie des Grigris et découvrez la diversité
        incroyable de ces totems numériques. Chacun raconte une histoire
        unique, reflétant les aspirations et les rêves de son propriétaire.
      </p>
      <ButtonLink
        color={palette[0]}
        bgColor={palette[1]}
        bgHover={palette[2]}
        to={`${config.baseLink}experience`}
      >
        Génerer mon grigri
      </ButtonLink>
      <InfiniteScroll
        dataLength={galleryData.length}
        next={loadMore}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
      >
        <div className="flex flex-wrap mt-10">
          {galleryData.map(({ width, height, name }, index) => (
            <div
              className="w-full min-[490px]:w-1/2 lg:w-1/4 min-[320px]:p-3 lg:p-6"
              key={index}
            >
              <img
                width={width}
                height={height}
                className="w-full h-auto block"
                src={`${process.env.REACT_APP_API_URL}/img/${name}`}
                alt={name}
              />
            </div>
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
}

export default Gallery;
