import { useContext } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ThemeContext } from "./context/theme";
import Home from "./components/pages/Home";
import config from "./config";
import Header from "./components/Header";
import Gallery from "./components/pages/Gallery";
import styled from "styled-components";
import Experience from "./components/pages/Experience";
import Footer from "./components/Footer";
import About from "./components/pages/About";

const MainBgStyled = styled.div(({ $page, $palette }) => ({
    backgroundColor: $palette[0],
    minHeight: "100vh",
    fontSize: "1.5rem",
    color: $palette[1],
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",
}));

const App = () => {
    const { palette } = useContext(ThemeContext);
    const location = useLocation();
    const page = location.pathname;

    return (
        <div className="font-TTNormsProReg">
            <MainBgStyled $page={page} $palette={palette}>
                <Header />
                <Routes>
                    <Route path={`${config.baseLink}`} element={<Home />} />
                    <Route
                        path={`${config.baseLink}experience`}
                        element={<Experience />}
                    />
                    <Route
                        path={`${config.baseLink}digitalgrigris`}
                        element={<Gallery />}
                    />
                    <Route
                        path={`${config.baseLink}a-propos`}
                        element={<About />}
                    />
                    <Route path="*" element={<Navigate to={`${config.baseLink}`} />} />
                </Routes>
                <Footer />
            </MainBgStyled>
        </div>
    );
};

export default App;
