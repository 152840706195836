function Footer() {
    

    return (
        <footer className={`p-6 md:p-12 text-base text-right`}>
            <a target="_blank" href="http://intuiti.net" rel="noreferrer">Intuiti.net</a>
        </footer>
    );
}

export default Footer;
