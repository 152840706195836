import { useState, useEffect, useContext } from "react";
import { ThemeContext, paletteRange } from "../context/theme";
import styled from "styled-components";
import CanvasGenerator from "./CanvasGenerator";
import config from "../config";

function Loader({ formData, onCanvasGenerated }) {
    const [enableGenerator, setEnableGenerator] = useState(false);
    const { palette, setPalette } = useContext(ThemeContext);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [step, setStep] = useState(0);
    const animationPaletteRange = [
        ["#FFFA7E", "#DD4A65"],
        ["#C1DEFF", "#95395A"],
        ["#AC9AF6", "#FFE9BF"],
        ["#FFE9BF", "#AC9AF6"],
    ];

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        setPalette(animationPaletteRange[step]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setStep(
                (prevStep) => (prevStep + 1) % animationPaletteRange.length
            );
        }, 500);

        if (enableGenerator) {
            clearTimeout(timeout);
        }

        return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, enableGenerator]);
    const fixImgClass = "text-center";
    return (
        <div className="flex flex-col items-center justify-center">
            {!enableGenerator ? (
                <div className="text-center">
                    {animationPaletteRange.map((palette, index) => (
                        <img
                            key={index}
                            style={{
                                display: `${step === index ? "inline-block" : "none"}`,
                            }}
                            className={`${fixImgClass}`}
                            src={`/grigri${index + 1}.png`}
                            alt={`Grigri ${index + 1}`}
                            width="191"
                            height="180"
                        />
                    ))}
                    <div className="text-center mt-6">
                        <StyledBtn
                            $palette={palette}
                            onClick={() => {
                                setPalette(paletteRange[formData.mood - 1]);
                                setEnableGenerator(!enableGenerator);
                            }}
                        >
                            Découvrir mon Grigri digital 2024
                        </StyledBtn>
                    </div>
                </div>
            ) : (
                <CanvasGenerator
                    formData={formData}
                    onCanvasGenerated={onCanvasGenerated}
                    hiddingMod={windowWidth < config.canvas.maxWidth}
                />
            )}
        </div>
    );
}

const StyledBtn = styled.button(({ $palette }) => ({
    backgroundColor: $palette[1],
    color: $palette[0],
    borderRadius: "100px",
    padding: "0.7rem 1.1rem",
    fontSize: "18px",
    transition: "backgroundColor 0.3s ease",
    "&:hover": {
        backgroundColor: $palette[1],
    },
}));

export default Loader;
