import { useContext, useState } from "react";
import { ThemeContext, paletteRange } from "../context/theme";
import { ReactComponent as Linkedin } from "../assets/linkedin.svg";
import { ReactComponent as Refresh } from "../assets/refresh.svg";
import ActionButton from "./ActionButton";
import CanvasGenerator from "./CanvasGenerator";

function GrigriResult({ formData, pictureData }) {
    const [pictureIsSaved, setPictureIsSaved] = useState(false);
    const [isRegenerateCanvas, setIsRegenerateCanvas] = useState(false);
    const { palette, setPalette } = useContext(ThemeContext);
    const [pictureDataState, setPictureDataState] = useState(pictureData);
    const [requestCounter, setRequestCounter] = useState(0);

    // incruste logo
    const saveImageToServer = async () => {
        try {
            if (requestCounter < 5) {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/gallery`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            imageData: pictureDataState.dataUrl,
                            grigriId: pictureDataState.grigriNum,
                        }),
                    }
                );

                const data = await response.json();

                if (data.success) {
                    setPictureIsSaved(true);
                    setRequestCounter(requestCounter + 1);
                } else {
                    console.error(
                        "Échec de l'enregistrement de l'image:",
                        data.message
                    );
                    throw new Error(data.message);
                }
            }
        } catch (error) {
            console.error("Erreur lors de l'enregistrement de l'image:", error);
        }
    };

    const resetCanvas = () => {
        const randomPaletteIndex = Math.floor(
            Math.random() * paletteRange.length
        );
        setPalette(paletteRange[randomPaletteIndex]);
        setIsRegenerateCanvas(!isRegenerateCanvas);
        setPictureIsSaved(false);
    };

    const uploadImage = async () => {
        try {
            const link = document.createElement("a");
            link.href = pictureDataState.dataUrl;
            link.download = `Grigrigital_${pictureDataState.grigriNum}.jpg`;
            link.click();
        } catch (error) {
            console.log("Error during upload");
        }
    };

    const linkedinShare = async () => {
        try {
            await saveImageToServer();
            const shareUrl = `${process.env.REACT_APP_API_URL}/?grigri=${pictureDataState.grigriNum}`;
            const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`;
            const popup = window.open(linkedinUrl, "_blank", "width=420,height=700");
            if(!popup) {
                window.location.href=linkedinUrl;
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onCanvasGenerated = (dataUrl, grigriNum) => {
        setIsRegenerateCanvas(!isRegenerateCanvas);
        setPictureDataState({ dataUrl, grigriNum });
    };

    return (
        <div>
            <div className="mx-auto w-full max-w-screen-xl">
                <div className="flex flex-wrap">
                    <div className="w-full pr-0 md:w-5/12 md:pr-12">
                        <div style={{ border: `1px solid ${palette[1]}` }}>
                            <img
                                className="w-full h-auto"
                                src={pictureDataState.dataUrl}
                                alt={pictureDataState.grigriNum}
                            />
                        </div>
                        {
                            <div
                                className="cursor-pointer flex items-center justify-center text-lg mt-5"
                                onClick={resetCanvas}
                            >
                                <Refresh className="inline-block mr-2" />
                                <span className="underline">
                                    Générer un nouveau
                                </span>
                            </div>
                        }
                    </div>
                    <div className="w-full pl-0 mt-8 md:mt-0 md:w-7/12 md:pl-12">
                        <h1 className="text-4xl md:text-[55px]/[55px] mb-5 font-TTNormsProMed">
                            {formData.name ? (
                                <>
                                    {formData.name},<br />
                                    voici votre Grigri
                                </>
                            ) : (
                                "Voici votre Grigri"
                            )}
                        </h1>
                        <p className="text-xl mb-5">
                            Tadaaa ! Voici votre Grigri, tout frais sorti du
                            four magique. Il est unique, il est beau, il est
                            puissant – un peu comme vous, quoi. Mais n'oubliez
                            pas : son pouvoir se décuple lorsqu'il est partagé.
                        </p>
                        <p className="text-xl font-TTNormsProMed mb-5">
                            Partagez votre Grigri sur les réseaux sociaux avec
                            le hashtag #MonGrigri2024 et invitez vos amis à
                            créer le leur. Ensemble, faisons de 2024 une année
                            remplie de succès, de bonheur et d'aventures
                            extraordinaires !
                        </p>
                        <div className="flex mb-6 justify-center md:justify-start">
                            <div
                                style={{ border: `1px solid ${palette[1]}` }}
                                className="rounded-full p-3 mr-4 cursor-pointer"
                                onClick={() => linkedinShare()}
                            >
                                <Linkedin className="width-[50px] height-[50px]" />
                            </div>
                        </div>
                        <p className="text-xl font-TTNormsProMed mb-6">
                            Satisfait de votre Grigri ?
                        </p>
                        <div className="flex flex-wrap">
                            <div
                                className="text-center w-full md:w-auto"
                                onClick={() => saveImageToServer()}
                            >
                                <ActionButton
                                    disabled={pictureIsSaved}
                                    className="m-0 md:mr-4 mb-6 w-full md:w-auto"
                                    bgColor={palette[1]}
                                    color={palette[0]}
                                >
                                    {pictureIsSaved
                                        ? "✅ Publié !"
                                        : "Publiez dans la galerie"}
                                </ActionButton>
                            </div>
                            <div
                                className="text-center w-full md:w-auto"
                                onClick={() => uploadImage()}
                            >
                                <ActionButton
                                    className="mr-4 mb-6 w-full md:w-auto"
                                    bgColor={palette[1]}
                                    color={palette[0]}
                                >
                                    Téléchargez-le
                                </ActionButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isRegenerateCanvas && (
                <CanvasGenerator
                    formData={formData}
                    hiddingMod={true}
                    onCanvasGenerated={onCanvasGenerated}
                />
            )}
        </div>
    );
}

export default GrigriResult;
