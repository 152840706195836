import Sketch from "react-p5";
import { ThemeContext } from "../context/theme";
import { useContext, useRef } from "react";
import { svgLogo } from "../utils";
import config from "../config";

function CanvasGenerator({ formData, onCanvasGenerated, hiddingMod=false }) {
    const { palette } = useContext(ThemeContext);
    const canvasRef = useRef(null);

    const CANVAS_MAX_WIDTH = config.canvas.maxWidth;
    const LOGO_ORIGINAL_HEIGHT = config.canvas.logoHeight;
    const GRID_COLUMNS = 10;
    let logoPosX, logoPosY;
    let squareSize; // Taille des carrés
    let padding; // Padding autour du canvas
    let gridSpacing; // Espacement de la grille

    const grigriNum = Math.floor(Math.random() * 90000) + 10000;

    const setup = (p5, canvasParentRef) => {
        // canvas creation
        p5.noLoop();
        p5.pixelDensity(2);
        let canvasSize = calculateCanvasSize(p5);
        const canvas = p5.createCanvas(canvasSize.width, canvasSize.height);

        // configureDrawingParameters
        padding = p5.width / 10;
        gridSpacing = (canvasSize.width - 2 * padding) / (GRID_COLUMNS - 1);
        squareSize = gridSpacing * 0.7;
        let scaleLogo = canvasSize.width / CANVAS_MAX_WIDTH;
        logoPosX = padding;
        logoPosY = p5.height - LOGO_ORIGINAL_HEIGHT * scaleLogo - padding;

        // ref canvas
        canvas.parent(canvasParentRef);
        canvasRef.current = canvas;
    };

    const calculateCanvasSize = (p5) => {
        let newWidth = p5.min(p5.windowWidth, CANVAS_MAX_WIDTH);
        let newHeight = newWidth * 1.17;

        if (newHeight > p5.windowHeight) {
            newHeight = p5.windowHeight;
            newWidth = newHeight / 1.17;
        }
        return { width: newWidth, height: newHeight };
    };

    const draw = (p5) => {
        p5.background(palette[0]);
        p5.noStroke();
        p5.rectMode(p5.CENTER);

        drawSquares(true, palette[1], p5); // Carrés réguliers
        drawSquares(false, palette[1], p5); // Carrés aléatoires
        drawLineAndLogo(palette[1], p5);

        // Appeler la fonction de rappel et passer l'objet URL généré
        if (canvasRef.current && onCanvasGenerated) {
            const canvasElement = canvasRef.current.elt;
            const canvasDataURL = canvasElement.toDataURL("image/jpeg", 1);
            onCanvasGenerated(canvasDataURL, grigriNum);
        }
    };

    function drawLineAndLogo(squareColor, p5) {
        let lineThickness = 2;
        let lineY = p5.height - padding * 1.8; // Position de la ligne
        p5.stroke(squareColor);
        p5.strokeWeight(lineThickness);
        p5.line(padding, lineY, p5.width - padding, lineY);

        let filename = "Grigri #" + p5.year() + "/" + grigriNum;
        let textPercentage = 0.03;
        let fontSize = p5.width * textPercentage;
        let textOffset = lineThickness + 5; // Espacement entre la ligne et le texte

        p5.noStroke();
        p5.textFont("Arial");
        p5.fill(squareColor);
        p5.textSize(fontSize);
        p5.textAlign(p5.RIGHT, p5.TOP);
        p5.text(filename, p5.width - padding, lineY + textOffset + 7);
        p5.text(`de ${formData.name ? formData.name : "Anonymous"}`, p5.width - padding, lineY + (textOffset * 4));
        let logoOffset = 15; // Espacement entre la ligne et le logo
        p5.push();
        let scaleLogo = p5.width / (CANVAS_MAX_WIDTH / 0.6);
        logoPosY = lineY + logoOffset; // Position du logo juste en dessous de la ligne
        p5.translate(logoPosX, logoPosY);
        p5.scale(scaleLogo);
        p5.fill(squareColor);
        svgLogo.forEach((cmd) => handleDrawingCommand(cmd, p5));
        p5.pop();
    }

    function handleDrawingCommand(cmd, p5) {
        // eslint-disable-next-line default-case
        switch (cmd.type) {
            case "beginShape":
                p5.beginShape();
                break;
            case "vertex":
                p5.vertex(...cmd.params);
                break;
            case "bezierVertex":
                p5.bezierVertex(...cmd.params);
                break;
            case "endShape":
                p5.endShape();
                break;
        }
    }

    function drawSquares(isLayer1, color, p5) {
        let nbSquares = isLayer1
            ? p5.floor(p5.random(30, 60))
            : p5.floor(p5.random(20, 30));
        //let nbSquares = isLayer1 ? 45 : 20;
        // p5.floor(p5.random(30 + (parseInt(formData.fervor) / 10), 60 + (parseInt(formData.fervor) / 10)));
        // p5.floor(p5.random(20 + (parseInt(formData.fervor) / 10) , 30 + (parseInt(formData.fervor) / 10)));

        let offset = isLayer1 ? 0 : gridSpacing / 2;
        //let radius = 5;

        for (let i = 0; i < nbSquares; i++) {
            let gridX = p5.floor(p5.random(1, GRID_COLUMNS));
            let gridY = p5.floor(p5.random(1, GRID_COLUMNS));
            let posX = gridX * gridSpacing + padding - gridSpacing + offset;
            let posY = gridY * gridSpacing + padding - gridSpacing + offset;

            p5.fill(color);
            p5.rect(posX, posY, squareSize, squareSize);
            p5.rect(p5.width - posX, posY, squareSize, squareSize);
        }
    }
    return <Sketch style={{border:`1px solid ${palette[1]}`, display: `${hiddingMod ? "none" : "block"}`}} setup={setup} draw={draw} />;
}

export default CanvasGenerator;
