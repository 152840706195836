import { useContext, useRef, useState } from "react";
import styled from "styled-components";
import { ThemeContext } from "./../context/theme";
import CustomSlider from "./Silder";
import SmallText from "./SmallText";

function ExperienceForm({ handleSubmit }) {
    const emojis = ["💸", "❤️‍🔥", "👽", "👏🏼", "🌏", "🚀", "👾", "🌞", "🏅", "🎉"];
    const rgpdRef = useRef(null);
    const { palette } = useContext(ThemeContext);
    const [stepState, setStepState] = useState(1);
    const [formData, setFormData] = useState({
        mood: "",
        fervor: "50",
        name: "",
    });
    const formDataKeys = Object.keys(formData);

    const handleInputChange = (step, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [step]: value,
        }));

        if (stepState === 1) handleNext();
    };

    const handleNext = () => {
        setStepState((prevStep) => prevStep + 1);
    };
    return (
        <div className="lg:w-3/4">
            {stepState === 1 && (
                <div>
                    <p className="mb-8 text-3xl md:text-5xl/tight font-TTNormsProMed">
                        Choisissez l'émoji qui va mettre le feu à votre 2024 !
                    </p>
                    <SmallText>
                        Amour, réussite, sérénité ou aventure épique...
                        Qu'est-ce qui parle à votre cœur ? Sélectionnez l'émoji
                        qui s'en rapproche le plus.
                    </SmallText>
                    <div className="flex flex-wrap justify-between sm:justify-around md:justify-start mb-12">
                        {emojis.map((emoji, index) => (
                            <StyledRadioButton
                                key={index}
                                className="mb-6 md:mr-6"
                                $palette={palette}
                            >
                                <input
                                    type="radio"
                                    id={`option${index + 1}`}
                                    name="options"
                                    value={index + 1}
                                    onChange={(e) =>
                                        handleInputChange(
                                            formDataKeys[0],
                                            e.target.value
                                        )
                                    }
                                />
                                <label htmlFor={`option${index + 1}`}>
                                    {emoji}
                                </label>
                            </StyledRadioButton>
                        ))}
                    </div>
                </div>
            )}

            {stepState === 2 && (
                <div>
                    <p className="mb-8 text-3xl md:text-5xl/tight font-TTNormsProMed">
                        Ensuite, glissez le curseur pour choisir votre numéro
                        fétiche.
                    </p>
                    <SmallText>
                        C'est un peu comme la roulette russe, mais en version
                        bonne fortune.
                    </SmallText>
                    <CustomSlider
                        onValueChange={(value) =>
                            handleInputChange(formDataKeys[1], value)
                        }
                    />
                    <StyledBtn
                        className="mt-10 w-full md:w-auto"
                        $palette={palette}
                        onClick={() => handleNext()}
                    >
                        Suivant
                    </StyledBtn>
                </div>
            )}

            {stepState === 3 && (
                <div>
                    <div>
                        <p className="mb-8 text-3xl md:text-5xl/tight font-TTNormsProMed">
                            Renseignez votre prénom
                        </p>
                        <SmallText>
                            C’est comme donner un peu de votre ADN digital pour
                            que votre Grigri se synchronise à votre vibe.
                        </SmallText>
                        <input
                            type="text"
                            style={{ borderBottom: `1px solid ${palette[1]}` }}
                            //autoFocus={true}
                            className="block w-full lg:w-1/2 mb-6 bg-transparent focus:outline-none focus:border-transparent"
                            value={formData.name}
                            maxLength={22}
                            onChange={(e) =>
                                handleInputChange(
                                    formDataKeys[2],
                                    e.target.value
                                )
                            }
                        />
                        <label className="mb-8 flex item-center" htmlFor="rgpd">
                            <input
                                ref={rgpdRef}
                                className="scale-125"
                                type="checkbox"
                                id="rgpd"
                            />
                            <span className="ml-3 text-lg">
                                Je veux que mon nom figure sur mon Grigri
                            </span>
                        </label>
                    </div>
                    <StyledBtn
                        $palette={palette}
                        className="w-full md:w-auto"
                        onClick={() =>
                            handleSubmit(formData, rgpdRef.current.checked)
                        }
                    >
                        Suivant
                    </StyledBtn>
                </div>
            )}
        </div>
    );
}

const StyledRadioButton = styled.div(({ $palette }) => ({
    input: {
        display: "none",
    },

    label: {
        borderRadius: "100px",
        padding: "0.5rem 3rem",
        fontSize: "3rem",
        cursor: "pointer",
        color: $palette[1],
        transition: "background-color 0.3s ease, color 0.3s ease",
        lineHeight: "normal",
        display: "block",
        backgroundColor: $palette[0],
        border: `1px solid ${$palette[1]}`,
        "&:hover": {
            border: "1px solid transparent",
            backgroundColor: $palette[1],
            color: $palette[0],
        },
    },
}));

const StyledBtn = styled.button(({ $palette }) => ({
    backgroundColor: $palette[1],
    color: $palette[0],
    borderRadius: "100px",
    padding: "0.7rem 4rem",
    fontSize: "1.2rem",
    transition: "backgroundColor 0.3s ease",
    "&:hover": {
        backgroundColor: $palette[1],
    },
}));

export default ExperienceForm;
