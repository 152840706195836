import { useState, useContext } from "react";
import styled from "styled-components";
import { ThemeContext } from "./../context/theme";

function CustomSlider({ onValueChange }) {
  const { palette } = useContext(ThemeContext);
  const [sliderValue, setSliderValue] = useState(50);

  const handleSliderChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setSliderValue(newValue);
    if (onValueChange) {
      onValueChange(newValue);
    }
  };

  return (
    <SliderContainer>
      <p className="text-center text-2xl font-bold mb-3">{sliderValue}</p>
      <Slider
        type="range"
        min={0}
        max={100}
        value={sliderValue}
        onChange={handleSliderChange}
        $palette={palette}
      />
    </SliderContainer>
  );
}

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  @media screen and (min-width: 768px) {
    margin-top: 3rem;
  }
  margin-top: 2rem;
`;

const Slider = styled.input.attrs({ type: "range" })`
  width: 100%;
  -webkit-appearance: none;
  height: 10px;
  border-radius: 5px;
  background: ${(props) => props.$palette[1]};
  outline: none;
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 60px;
    height: 34px;
    border-radius: 100px;
    background: ${(props) => props.$palette[0]};
    cursor: pointer;
    border: 1px solid ${(props) => props.$palette[1]}
  }

  &::-moz-range-thumb {
    width: 60px;
    height: 34px;
    border: none;
    border-radius: 50%;
    background: ${(props) => props.$palette[2]};
    cursor: pointer;
  }
`;

export default CustomSlider;
